import React from "react";

import ContactForm from "../../../../ContactForm";

const TradingAndSupply = ({
    useFormalAddress,
    lang,
    name = "trading-and-supply",
}) => {
    return (
        <ContactForm
            urlEnding="trading-and-supply"
            pushToGTMString={`${name}:contact-form`}
            canSendStorageString={`${name}::contact-form:sent-at`}
            scrollToAnchor="contact"
            useFormalAddress={useFormalAddress}
            lang={lang}
        />
    );
};

export default TradingAndSupply;
